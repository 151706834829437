import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ICredencias } from './../../interfaces/credenciais.interface';
import { IAgendaConfirma, IAgendaCancela } from './../../interfaces/agenda.interface';
import { AuthenticationService } from '../../services/api/authentication.service';
import { AgendaService } from '../../services/api/agenda.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { ConfirmationComponent } from './../../components/confirmation/confirmation.component';
import { CancelaConsultaComponent } from './../../components/cancela-consulta/cancela-consulta.component';
import { DefineBaseUrlService } from './../../services/api/define-base-url.service';

@Component({
  selector: 'app-url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.css'],
})

export class UrlComponent implements OnInit {
  versaoApp: string;
  idConsulta: string = '';
  unidade: string = '';
  paciente: string = '';
  dataNascimento: string = '';
  medico: string = '';
  especialidade: string = '';
  dataAgenda: string = '';
  mensagem: string = '';
  podeConfirmar: boolean = false
  podeCancelar: boolean = false

  constructor(
    private route: ActivatedRoute,
    private apiAuth: AuthenticationService,
    private apiBase: DefineBaseUrlService,
    private apiAgenda: AgendaService,
    private snackBar: SnackBarService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.versaoApp = this.apiBase.getVersionApp();

    this.route.params.subscribe(parametros => {
      if (parametros['id']) {
        this.idConsulta = parametros['id'];
      }

      this.login();
    });
  }

  limpaAtendimento() {
    this.unidade = '';
    this.paciente = '';
    this.dataNascimento = '';
    this.medico = '';
    this.especialidade = '';
    this.dataAgenda = '';
    this.mensagem = '';
    this.podeConfirmar = false;
    this.podeCancelar = false;
  }

  login() {
    const credentials: ICredencias = {
      usuario: '',
      senha: '',
      idConsulta: this.idConsulta,
      versaoApp: ''
    };

    this.apiAuth.login(credentials).subscribe(
      () => {
        this.unidade = this.apiAuth.unidade;
        this.paciente = this.apiAuth.paciente;
        this.dataNascimento = this.apiAuth.dataNascimento;
        this.medico = this.apiAuth.medico;
        this.especialidade = this.apiAuth.especialidade;
        this.dataAgenda = this.apiAuth.dataAgenda;
        this.mensagem = this.apiAuth.mensagem;
        this.podeConfirmar = this.apiAuth.podeConfirmar;
        this.podeCancelar = this.apiAuth.podeCancelar;
      },
      (res) => {

        if (res.error.error) {
          let msg = res.error.error + ' ';

          if (res.error.description) { msg = msg + res.error.description }

          this.mensagem = msg
          this.snackBar.present(msg);

        } else {
          this.snackBar.present('Falha ao efetuar o login');
        }
      }
    );
  }

  confirmaAtendimento() {
    const conf = this.dialog.open(ConfirmationComponent, {
      height: '200px',
      hasBackdrop: false,
      data: {
        title: 'Confirmar Consulta:',
        message: 'Confirma o atendimento no dia e horário informado?',
      },
    });
    conf.afterClosed().subscribe((data) => {
      if (data && data.confirm) {
        const dados: IAgendaConfirma = {
          idConsulta: this.idConsulta,
          unidade: '',
          paciente: '',
          dataNascimento: '',
          medico: '',
          especialidade: '',
          dataAgenda: '',
          mensagem: '',
          podeConfirmar: '',
          podeCancelar: ''
        };
        this.apiAgenda.confirmaConsulta(dados).subscribe((_) => {
          this.limpaAtendimento();
          this.snackBar.present('Atendimento Confimado');
        });
      }
    });
  }

  cancelaAtendimento() {
    const conf = this.dialog.open(CancelaConsultaComponent, {
      height: '400px',
      width: '320px',
      data: {
        idConsulta: this.idConsulta,
        motivoCancelamento: ''
      },
      hasBackdrop: false,
    });
    conf.afterClosed().subscribe((result) => {
      if (result && result.data) {
        const dados: IAgendaCancela = {
          idConsulta: this.idConsulta,
          motivoCancelamento: result.data.motivoCancelamento
        };
        this.apiAgenda.cancelaConsulta(dados).subscribe(() => {
          this.mensagem = this.apiAuth.mensagem;
          this.snackBar.present('Atendimento Cancelado');
          this.limpaAtendimento();
        });
      }
    });
  }
}
