import { IAgendaConfirma, IAgendaCancela } from './../../interfaces/agenda.interface';
import { IMotivosCancelamento } from './../../interfaces/motivos.interface';
import { DefineBaseUrlService } from './define-base-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AgendaService {
    private baseUrl = this.defineBaseUrl.getApiBaseUrl();
    constructor(
        private http: HttpClient,
        private defineBaseUrl: DefineBaseUrlService
    ) {

    }

    confirmaConsulta(body: IAgendaConfirma): Observable<any> {
        return this.http
            .post<IAgendaConfirma>(`${this.baseUrl}/whatsapp/confirmaConsulta`, body)
            .pipe(map((res) => res));
    }

    cancelaConsulta(body: IAgendaCancela): Observable<any> {
        return this.http
            .post<IAgendaCancela>(`${this.baseUrl}/whatsapp/cancelaConsulta`, body)
            .pipe(map((res) => res));
    }

    //motivos cancelamento
    // getMotivosCancelamento2(idConulta: string): Observable<IMotivosCancelamento[]> {
    //     return this.http
    //         .get<IMotivosCancelamento[]>(`${this.baseUrl}/whatsapp/motivosCancelamento`)
    //         .pipe(map((res) => res));
    // }

    getMotivosCancelamento(idConsulta: string): Observable<IMotivosCancelamento[]> {
        return this.http
            .get<IMotivosCancelamento[]>(`${this.baseUrl}/whatsapp/motivosCancelamento`, {
                params: { idConsulta },
            })
            .pipe(map((res) => res));
    }


}
