<form [formGroup]="formData">
    <mat-card class="agenda-card">

        <h1 mat-dialog-title>Cancelar a Consulta</h1>

        <mat-form-field class="field_motivo" appearance="outline">
            <mat-label>Motivo do Cancelamento</mat-label>
            <mat-select formControlName="motivoCancelamento" required>
                <mat-option *ngFor="let i of motivosCancelamento" [value]="i.C_COD_MOTIVO">{{ i.C_DESC_MOTIVO }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <h2 mat-dialog-content>Confirma o cancelamento da consulta?</h2>

        <mat-card-actions>
            <button (click)="confirma()" mat-raised-button color="accent">Sim</button>
            <button (click)="cancela()" mat-raised-button color="primary">Não</button>
        </mat-card-actions>
    </mat-card>
</form>