import { DefineBaseUrlService } from './define-base-url.service';
import { SnackBarService } from '../snack-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from './../local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ICredencias } from './../../interfaces/credenciais.interface';
import { IAutenticacao } from './../../interfaces/autenticacao.interface';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private baseUrl = this.defineBaseUrl.getApiBaseUrl();
  public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentAccessToken = null;
  public currentRefreshToken = null;
  public currentFcmToken = null;

  public unidade: string = '';
  public paciente: string = '';
  public dataNascimento: string = '';
  public medico: string = '';
  public especialidade: string = '';
  public dataAgenda: string = '';
  public mensagem: string = '';
  public podeConfirmar: boolean = false;
  public podeCancelar: boolean = false;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: SnackBarService,
    private defineBaseUrl: DefineBaseUrlService
  ) {
    this.loadToken();
    this.loadFcmToken();
  }

  login(credenciais: ICredencias): Observable<any> {
    credenciais.usuario = 'sollid.solutions'
    credenciais.senha = 'P@ssw0rd'
    credenciais.versaoApp = this.defineBaseUrl.getVersionApp();

    return this.http
      .post<IAutenticacao>(`${this.baseUrl}/controleacesso/loginConfirmaAgenda`, credenciais)
      .pipe(
        map((res) => res),
        switchMap((res) => {
          this.currentAccessToken = res.accessToken;
          this.currentRefreshToken = res.refreshToken;
          this.localStorage.set('USER', credenciais.usuario);
          this.localStorage.set('ACCESS_TOKEN', res.accessToken);
          this.localStorage.set('REFRESH_TOKEN', res.refreshToken);
          this.isAuthenticated.next(true);

          this.unidade = res.unidade;
          this.paciente = res.paciente;
          this.dataNascimento = res.dataNascimento;
          this.medico = res.medico;
          this.especialidade = res.especialidade;
          this.dataAgenda = res.dataAgenda;
          this.mensagem = res.mensagem;
          this.podeConfirmar = (res.podeConfirmar == 'S');
          this.podeCancelar = (res.podeCancelar == 'S');

          return of(null);
        }),
        tap((_) => {
          this.isAuthenticated.next(true);
        })
      );
  }

  clearSession() {
    this.localStorage.remove('ACCESS_TOKEN');
    this.localStorage.remove('REFRESH_TOKEN');
    this.localStorage.remove('USER_NAME');
    this.localStorage.remove('USER_PERFIL');
    this.localStorage.remove('UNIDADE');
  }

  sessionExpired() {
    this.currentAccessToken = null;
    this.currentRefreshToken = null;
    this.isAuthenticated.next(false);
    this.clearSession();
    this.snackBar.present('Sessão expirada');
    const url = this.activatedRoute.snapshot['_routerState'].url;
    if (url !== '/home' && url !== '/url') {
      this.router.navigate(['/url'], { queryParams: { returnto: url } });
    }
    else {
      this.router.navigateByUrl('/url', { replaceUrl: true });
    }
  }

  public storeAccessToken(accessToken: string) {
    this.currentAccessToken = accessToken;
    this.localStorage.set('ACCESS_TOKEN', accessToken);
  }

  public storeRefreshToken(refreshToken: string) {
    this.localStorage.set('REFRESH_TOKEN', refreshToken);
  }

  loadToken() {
    const token = this.localStorage.get('ACCESS_TOKEN');
    const refreshToken = this.localStorage.get('REFRESH_TOKEN');
    if (token) {
      this.currentAccessToken = token;
      this.currentRefreshToken = refreshToken;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  loadFcmToken() {
    const fcmToken = this.localStorage.get('fcm-token');
    if (fcmToken) {
      this.currentFcmToken = fcmToken;
    }
  }

  refreshToken(): Observable<IAutenticacao> {
    return this.http
      .post<IAutenticacao>(`${this.baseUrl}/autenticacao/refreshToken`, {})
      .pipe(map((res) => res));
  }
}
