<div class="full-page">

  <mat-card class="agenda-card">

    <div class="logo">
      <p><img style="width: 150px;" src="assets/logo_p.jpg" align="middle" /></p>
    </div>

    <mat-card-title>Unidade {{unidade}}</mat-card-title>

    <mat-card-content>
      <div class="titulo">
        <p>Paciente / Data Nascimento:</p>
      </div>

      <div class="texto">
        <p>{{paciente + ' / ' + dataNascimento}}</p>
      </div>

      <div class="titulo">
        <p>Profissional:</p>
      </div>

      <div class="texto">
        <p>{{medico}}</p>
      </div>

      <div class="titulo">
        <p>Especialidade:</p>
      </div>

      <div class="texto">
        <p>{{especialidade}}</p>
      </div>

      <div class="titulo">
        <p>Data Hora Agendada:</p>
      </div>

      <div class="texto">
        <p>{{dataAgenda}}</p>
      </div>
    </mat-card-content>

    <div>
      <p style="color:blue">{{mensagem}}</p>
    </div>

    <mat-card-actions>
      <button mat-raised-button color="accent" class="agenda-button" (click)="confirmaAtendimento()"
        [disabled]="! podeConfirmar">
        Confirmar
      </button>
      <button mat-raised-button color="primary" class="agenda-button" (click)="cancelaAtendimento()"
        [disabled]="! podeCancelar">
        Desmarcar
      </button>
    </mat-card-actions>

    <div class="sollid">
      <p>Sollid Solutions CopyRight© 2023 - Versão {{ versaoApp }}</p>
    </div>

  </mat-card>
</div>