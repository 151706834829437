import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UrlModule } from './pages/url/url.module';
import { PoliticaModule } from './pages/politica/politica.module';

const routes: Routes = [
  {
    path: 'url/:id',
    loadChildren: () => import('./pages/url/url.module').then((m) => m.UrlModule),
  },
  {
    path: 'politica',
    loadChildren: () => import('./pages/politica/politica.module').then((m) => m.PoliticaModule),
  },
  {
    path: '',
    redirectTo: '/url',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), UrlModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
