import { AuthenticationService } from './../services/api/authentication.service';
import { LoaderService } from './../services/loader.service';
import { SnackBarService } from './../services/snack-bar.service';
import {
  Observable,
  throwError,
  BehaviorSubject,
  of,
  TimeoutError,
} from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  timeout,
  tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IAutenticacao } from '../interfaces/autenticacao.interface';
import { DefineBaseUrlService } from '../services/api/define-base-url.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private baseUrl = this.apiurl.getApiBaseUrl()
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken = false;
  private requests: HttpRequest<any>[] = [];

  constructor(
    private authApi: AuthenticationService,
    private router: Router,
    private snackBar: SnackBarService,
    private loaderService: LoaderService,
    private apiurl: DefineBaseUrlService
  ) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  clearAllRequests() {
    this.requests = [];
    this.loaderService.isLoading.next(false);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isInBlockedList(request.url)) {
      return next.handle(this.addPlatform(request));
    } else if (this.isRefreshToken(request.url)) {
      return next.handle(this.addRefreshToken(request));
    } else {
      this.requests.push(request);
      this.loaderService.isLoading.next(true);
      return new Observable((observer) => {
        const subscription = next
          .handle(this.addToken(request))
          .pipe(
            timeout(30000),
            catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                switch (err.status) {
                  case 400:
                    this.handle400Error(err);
                    return throwError(err);
                  case 401:
                    return this.handle401Error(request, next);
                  default:
                    return throwError(err);
                }
              } else if (err instanceof TimeoutError) {
                return this.handleTimeoutError(err);
              } else {
                return throwError(err);
              }
            })
          )
          .subscribe(
            (event) => {
              if (event instanceof HttpResponse) {
                this.removeRequest(request);
                observer.next(event);
              }
            },
            (err) => {
              this.removeRequest(request);
              observer.error(err);
            },
            () => {
              this.removeRequest(request);
              observer.complete();
            }
          );
        return () => {
          this.removeRequest(request);
          subscription.unsubscribe();
        };
      });
    }
  }

  private isInBlockedList(url: string): boolean {
    if (url == `${this.baseUrl}/controleacesso/loginConfirmaAgenda`) {
      return true;
    } else {
      return false;
    }
  }

  private isRefreshToken(url: string): boolean {
    if (url === `${this.baseUrl}/controleacesso/refreshToken`) {
      return true;
    } else {
      return false;
    }
  }

  private addToken(req: HttpRequest<any>) {
    if (this.authApi.currentAccessToken) {
      return req.clone({
        headers: new HttpHeaders({
          Platform: 'WebApp',
          authorization: `Bearer ${this.authApi.currentAccessToken}`,
        }),
      });
    } else {
      return req;
    }
  }

  private addRefreshToken(req: HttpRequest<any>) {
    if (this.authApi.currentRefreshToken) {
      return req.clone({
        headers: new HttpHeaders({
          Platform: 'WebApp',
          Authorization: `Bearer ${this.authApi.currentRefreshToken}`,
        }),
      });
    } else {
      return req;
    }
  }

  private addPlatform(req: HttpRequest<any>) {
    return req.clone({
      headers: new HttpHeaders({
        Platform: 'WebApp',
      }),
    });
  }

  private async handleTimeoutError(err) {
    this.snackBar.present('Timeout na comunicação com o servidor', 5000);
    this.router.navigateByUrl('/', { replaceUrl: true });
    return of(null);
  }

  private async handle400Error(err) {
    if (err.error.error) {
      this.snackBar.present(err.error.error, 5000, true);
    }
    else if (err.error) {
      this.snackBar.present(err.error, 5000, true);
    }
    else {
      this.snackBar.present('Falha no processamento', 5000, true);
    }
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.tokenSubject.next(null);
      this.isRefreshingToken = true;
      this.authApi.currentAccessToken = null;
      return this.authApi.refreshToken().pipe(
        switchMap((res: IAutenticacao) => {
          if (res) {
            const accessToken = res.accessToken;
            const refreshToken = res.refreshToken;
            this.authApi.storeAccessToken(accessToken);
            this.authApi.storeRefreshToken(refreshToken);
            this.tokenSubject.next(accessToken);
            return next.handle(this.addToken(req));
          } else {
            return of(null);
          }
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
        catchError(() => {
          this.clearAllRequests();
          this.authApi.sessionExpired();
          return of(null);
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((_) => next.handle(this.addToken(req)))
      );
    }
  }
}
