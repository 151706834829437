import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar, private router: Router) {}

  present(message: string, duration?: number, isError?: boolean) {
    this.snackBar.open(message, 'Fechar', {
      duration: duration ? duration : 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
