import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'src/app/windowProviders';

@Injectable({
  providedIn: 'root'
})
export class DefineBaseUrlService {

  constructor(@Inject(WINDOW) private window: Window) { }

  private getHostname(): string {
    return this.window.location.hostname;
  }

  getVersionApp(): string {
    return '1.0.2';
  }

  getApiBaseUrl(): string {
    const hostname = this.getHostname();
    //local
    if (hostname == 'localhost') {
      return 'http://localhost:9000/api'
    }
    //ambiente produção 
    else if (hostname == 'agenda.oncominas.med.br') {
      return 'https://app.oncominas.med.br:9000/api';
    }
    else {
      return 'https://app.oncominas.med.br:9000/api';
    }
  }
}
