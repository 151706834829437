import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snack-bar.service';

import { IMotivosCancelamento } from './../../interfaces/motivos.interface';
import { AgendaService } from '../../services/api/agenda.service';

export interface DialogDataCancelaConsulta {
  idConsulta: string;
  motivoCancelamento: string;
}

@Component({
  selector: 'app-cancela-consulta',
  templateUrl: './cancela-consulta.component.html',
  styleUrls: ['./cancela-consulta.component.css']
})
export class CancelaConsultaComponent implements OnInit {

  formData = new FormGroup({
    motivoCancelamento: new FormControl(
      {
        value: '',
        disabled: false
      },
      Validators.required
    ),
  });

  motivosCancelamento: IMotivosCancelamento[];

  constructor(
    private dialogRef: MatDialogRef<CancelaConsultaComponent>,
    private apiAgenda: AgendaService,
    private snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCancelaConsulta
  ) { }

  ngOnInit(): void {
    this.loadMotivosCancelamento();
  }

  confirma() {
    this.formData.markAllAsTouched();
    if (this.formData.valid) {
      console.log('motivoCancelamento ' + this.formData.value.motivoCancelamento)
      this.dialogRef.close({
        data: {
          motivoCancelamento: this.formData.value.motivoCancelamento,
        },
      }
      );
    }
    else {
      this.snackBar.present('Informe o motivo do cancelamento!');
    }
  }

  cancela() {
    this.dialogRef.close(false);
  }

  loadMotivosCancelamento() {
    this.apiAgenda.getMotivosCancelamento(this.data.idConsulta).subscribe((res) => {
      this.motivosCancelamento = res;
    });
  }

}
